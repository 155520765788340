import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import URL_MAPPING from "../routes/constants";
import EmpList from "../assets/icons/emp-list.svg";
import EmpListDark from "../assets/icons/emp-list-dark.svg";
import MapView from "../assets/icons/map-view.svg";
import MapViewDark from "../assets/icons/map-view-dark.svg";
import Home from "../assets/icons/home-icon.svg";
import Dashboard from "../assets/icons/dashboard.svg";
import DashboardDark from "../assets/icons/dashboard-dark.svg";
import VisitReport from "../assets/icons/visit-report.svg";
import VisitReportDark from "../assets/icons/visit-report-dark.svg";
import { useTranslation } from "react-i18next";
import DownArrow from "../assets/down-arrow.svg";
import UpArrow from "../assets/up-arrow.svg";
interface NavbarProps {
  open: boolean;
  setOpen: any;
}
const Navbar: React.FC<NavbarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [navItems, setNavItems] = useState<any>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null); // Track the hovered item
  const navRef = useRef<HTMLDivElement>(null);
  const isActive = (route: string) => location.pathname === route;
  const [isRTL, setIsRTL] = useState(false);
  const [submenuPosition, setSubmenuPosition] = useState<number>(0);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const handleSubMenuClick = (route: string) => {
    navigate(route); 
    setIsReportsOpen(false); 
    setOpen(false); 
  };
  const handleSection = async (section: any) => {
    switch (section) {
      case "My Team":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          {
            route: URL_MAPPING.ANALYTICS,
            label: t("Nav.dashboard"),
            icon: Dashboard,
            iconDark: DashboardDark,
          },
          // {
          //   route: URL_MAPPING.ANALYTICS,
          //   label:  t("Nav.analytics"),
          //   icon: Icon11,
          //   iconDark: Icon11s,
          // },

          {
            route: URL_MAPPING.MAPVIEW,
            label: t("Nav.mapView"),
            icon: MapView,
            iconDark: MapViewDark,
          },
          {
            label: t("Nav.reports"), // New "Reports" option
            icon: VisitReport, // Icon for the "Reports" option
            iconDark: VisitReportDark,
            isCollapsible: true, // Mark this as collapsible
            subItems: [
              {
                route: URL_MAPPING.VISITREPORT,
                label: t("Nav.visitsReport"),
                icon: VisitReport,
                iconDark: VisitReportDark,
              },
              {
                route: URL_MAPPING.ACTIVITYREPORT,
                label: t("Nav.activityReport"),
                icon: EmpList,
                iconDark: EmpListDark,
              },
            ],
          },
        ]);
        break;
      case "Customers":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          // {
          //   route: URL_MAPPING.AGINGREPORT,
          //   label:  t("Nav.agingReport"),
          //   icon: MapView,
          //   iconDark: MapViewDark,
          // },
          {
            route: URL_MAPPING.MASTERLIST,
            label: t("Nav.masterList"),
            icon: EmpList,
            iconDark: EmpListDark,
          },
        ]);

        break;
      case "Planogram":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
          {
            route: URL_MAPPING.PLANOGRAM,
            label:t("Nav.planogramreport") ,
            icon: EmpList,
            iconDark: EmpListDark,
          },
          // {
          //   route: URL_MAPPING.LANDING,
          //   label: "Master List",
          //   icon: MasterList,
          //   iconDark: MasterList,
          // },
        ]);
        break;
      case "Admin":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
      case "Print":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home,
          },
          {
            route: URL_MAPPING.DEPOSITREPORT,
            label:t("Nav.depositreport"),
            icon: VisitReport,
            iconDark: VisitReportDark,
          },
          {
            route: URL_MAPPING.SALESREPORT,
            label: t("Nav.salesreport"),
            icon: EmpList,
            iconDark: EmpListDark,
          },
          {
            route: URL_MAPPING.INVOICEREPORT,
            label:t("Nav.invoicereport"),
            icon: MapView,
            iconDark: MapViewDark,
          },
        ]);
        break;
      case "Territory":
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: "Home",
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
      default:
        setNavItems([
          {
            route: URL_MAPPING.LANDING,
            label: t("Nav.home"),
            icon: Home,
            iconDark: Home, // Assuming Home doesn't have a dark version
          },
        ]);
        break;
    }
  };

  const dir = document.documentElement.getAttribute("dir");
  useEffect(() => {
    const section = localStorage.getItem("section");
    if (!section) {
      navigate(URL_MAPPING.LANDING);
    }
    handleSection(section);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dir]);

  useEffect(() => {
    let isRtl = false;
    isRtl = dir === "rtl";
    setIsRTL(isRtl);
  }, [dir]);
  const handleReportsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsReportsOpen((prev) => !prev);
    const buttonOffset = event.currentTarget.getBoundingClientRect();
    setSubmenuPosition(buttonOffset.top);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setOpen(false); // Close the Navbar when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
<div
  ref={navRef}
  className={`md:flex h-screen md:h-[calc(100%-51px)] border-2 bg-[#FFFFFF] flex flex-col justify-start items-center absolute ${
    isRTL ? "right-0" : "left-0"
  } gap-0 z-30 top-0 md:top-[48px] 
  ${open ? "w-[251px] md:w-[220px]" : "hidden w-[56px] delay-150"} transition-all duration-300`}
  onMouseEnter={() => setOpen(true)}
  onMouseLeave={() => {
    setOpen(false); // Close the navbar when mouse leaves the navbar
    setIsReportsOpen(false); // Close the submenu when the navbar closes
  }}
>
  <ul className="flex flex-col items-center justify-start gap-1 mt-4 w-full overflow-hidden">
    {navItems.map((item: any, index: number) => {
      const isActiveSubMenu = item.label === t("Nav.reports") && isReportsOpen;
      const isAnySubMenuActive =
        item.label === t("Nav.reports") &&
        navItems
          .find((navItem: any) => navItem.label === t("Nav.reports"))
          ?.subItems.some((subItem: any) => isActive(subItem.route));

      return (
        <li
          key={index}
          className={`w-full items-center h-[54px] px-2 flex ${
            item.route === URL_MAPPING.LANDING
              ? "hover:bg-[#F3E6F4] hover:text-purple"
              : ""
          } group cursor-pointer ${
            isActive(item.route) || isActiveSubMenu || isAnySubMenuActive
              ? "bg-[#B0F7F5]"
              : ""
          }`}
        >
          <button
            className="p-2 flex gap-2 cursor-pointer items-center relative w-full justify-between"
            onClick={() => {
              if (item.isCollapsible) {
                setIsReportsOpen((prev) => !prev); // Toggle submenu visibility
              } else {
                navigate(item.route); // Navigate for non-collapsible items
              }
            }}
          >
            <div className="flex gap-2 items-center">
              <img
                src={
                  isActive(item.route) ||
                  hoveredIndex === index ||
                  isAnySubMenuActive
                    ? item.iconDark
                    : item.icon
                }
                alt={item.label}
                className="max-w-[50px]"
              />
              <p
                className={`transition-opacity duration-200 delay-150 ${
                  open ? "opacity-100" : "opacity-0"
                } group-hover:opacity-100 group-hover:text-black ${
                  isActive(item.route) || isAnySubMenuActive
                    ? "text-black"
                    : "text-grey"
                }`}
              >
                {item.label}
              </p>
            </div>

            {/* Add down arrow for collapsible items */}
            {item.isCollapsible && (
              <span>
                 <img src={isReportsOpen ? UpArrow : DownArrow} alt="" />
              </span>
            )}
          </button>
        </li>
      );
    })}
  </ul>

  {/* Submenu */}
  {isReportsOpen && (
    <ul
      className={`absolute bg-white shadow-lg ml-6  flex flex-col gap-2 z-50 submenu transition-opacity duration-200 delay-150 ${
        isRTL ? "right-[25px]" : ""
      } md:absolute md:top-[200px]`}
      style={{
        top: "250px", // Adjust position for larger screens
        minWidth: "190px",
      }}
    >
      {navItems
        .find((item: any) => item.label === t("Nav.reports"))
        ?.subItems.map((subItem: any, subIndex: number) => (
          <li
            key={subIndex}
            onClick={() => handleSubMenuClick(subItem.route)}
            className={`w-full  items-center h-[54px] p-2 flex group cursor-pointer ${
              isActive(subItem.route) ? " bg-[#B0F7F5]" : "group-hover:text-black"
            }`}
          >
            <button className="p-2 flex gap-2 items-center ">
              <img
                src={
                  isActive(subItem.route) ? subItem.iconDark : subItem.icon
                }
                alt={subItem.label}
                className="max-w-[50px]"
              />
              <p
                className={`transition-opacity duration-200 delay-150 ${
                  open ? "opacity-100" : "opacity-0"
                } group-hover:opacity-100 group-hover:text-black ${
                  isActive(subItem.route) ? "text-black " : "text-grey"
                }`}
              >
                {subItem.label}
              </p>
            </button>
          </li>
        ))}
    </ul>
  )}
</div>




  );
};  

export default Navbar;
