const URL_MAPPING = {
  LOGIN: "/auth/login",
  SIGNUP: "/auth/verifymail",
  FORGETPASS: "/forget-pass",
  LOGIN_ROOT: "/",
  TEST: "test",
  ANALYTICS: "/analytics",
  SALESMAN: "/map-view",
  CUSTOMERLIST: "/customer-list",
  TERRITORIES: "/territories",
  DASHBOARD: "/dashboard",
  CUSTOMERLISTDETAILS: "/customer-list-details",
  LANDING: "/home",
  RESETPASS: "/reset-password",
  GOOGLEAUTH: "/auth/verfiy-qr",
  EMPLOYEETABLE: "/employee-table",
  MASTERLIST: "/master-list",
  AGINGREPORT: "/aging-report",
  ACTIVITYREPORT: "/activity-report",
  PLANOGRAM: "/planogram",
  NOTIFICATIONS: "/notifications",
  VISITREPORT: "/visit-report",
  DEPOSITREPORT: "/deposit-report",
  SALESREPORT: "/sales-report",
  INVOICEREPORT: "/invoice-report",
  MAPVIEW: "/map-view-updated"

};

export default URL_MAPPING;
