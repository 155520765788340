import React from "react";
import Loader from "./Loader";
import { formatNumberWithoutFraction } from "../utility";
import { useTranslation } from "react-i18next";

type Leader = {
  id: number;
  emp_id: string;
  name_eng: string;
  name_arb:string;
  percentage_sold: number;
  cartons_sold: number;
  cartons_target: number;
  position_number: number;
};

type LeaderTableProps = {
  leaderData: any;
  loader: boolean;
  getIndex: (leaderData: any, index: number) => number;
};

const LeaderTable: React.FC<LeaderTableProps> = ({
  leaderData,
  loader,
  getIndex,
  
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
      <table className="min-w-full table-auto text-left">
        <thead className="sticky top-0 w-full">
          <tr className="bg-[#DEDEDE] text-white">
            <th className="px-2  text-center text-black py-2 text-13 font-bold">#</th>
            <th className="px-4 text-black py-2 text-13 font-bold">
              {t("leaderboard.Employee Name/ID")}
            </th>
            <th className="px-4 text-black py-2 text-13 font-bold max-w-[130px]">
              {t("leaderboard.Actual/ Target")}
            </th>
            <th className="px-4 text-black py-2 text-13 font-bold">%</th>
          </tr>
        </thead>
        <tbody>
          {leaderData?.map((leader: Leader, index: number) => (
            <tr key={index} className="bg-white border-b">
              <td className="px-2 text-center py-2 text-gray-700 text-13 font-bold">
                {leader.position_number}
              </td>
              <td className="px-2 py-2 text-gray-700 text-13 font-bold max-w-[180px]">
                <div className="flex flex-col">
                  {/* Dynamically switch between name_eng and name_ar */}
                  <p className="font-bold">
                    {i18n.language === "ar" ? leader.name_arb : leader.name_eng}
                  </p>
                  <p className="text-[10px] font-medium">{leader.emp_id}</p>
                </div>
              </td>
              <td className="px-4 py-2 text-gray-700 text-13 font-bold">
                {formatNumberWithoutFraction(leader?.cartons_sold)}/
                {formatNumberWithoutFraction(Math.max(Number(leader.cartons_target), 1))}
              </td>

              <td className="px-4 py-2 text-gray-700 text-13 font-bold">
                {Math.max(Number(leader.percentage_sold), 1)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>{loader && <Loader />}</div>
    </>
  );
};

export default LeaderTable;
