import React, { useState, useRef } from "react";
import Close from "../assets/close-circle-white.svg";

interface ZoomedImagePopupProps {
  imageUrl: string;
  onClose: () => void;
}

const ZoomedImagePopup: React.FC<ZoomedImagePopupProps> = ({ imageUrl, onClose }) => {
  const [scale, setScale] = useState(1);
  const [transformOrigin, setTransformOrigin] = useState("center center");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cursorStyle, setCursorStyle] = useState("default");
  const imageRef = useRef<HTMLImageElement | null>(null);
  const startDrag = useRef({ x: 0, y: 0 });
  const isDragging = useRef(false);

  const handleZoom = (event: React.WheelEvent) => {
    event.preventDefault();

    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      let newScale = scale;
      if (event.deltaY < 0) {
        newScale = Math.min(scale * 1.1, 5);
        setTransformOrigin(`${(mouseX / rect.width) * 100}% ${(mouseY / rect.height) * 100}%`);
        setCursorStyle("zoom-in");
      } else {
        newScale = Math.max(scale / 1.1, 1);
        setCursorStyle("zoom-out");
      }

      setScale(newScale);

      setTimeout(() => {
        setCursorStyle("default");
      }, 200);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    isDragging.current = true;
    startDrag.current = { x: e.clientX, y: e.clientY };
    setCursorStyle("grabbing");
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging.current && imageRef.current) {
      const deltaX = e.clientX - startDrag.current.x;
      const deltaY = e.clientY - startDrag.current.y;

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startDrag.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    setCursorStyle("grab");
  };

  const handleMouseLeave = () => {
    if (isDragging.current) {
      isDragging.current = false;
      setCursorStyle("grab");
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="relative rounded-lg p-8 max-w-4xl w-auto h-full overflow-auto"
        onClick={(e) => e.stopPropagation()}
        onWheel={handleZoom}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        style={{
          transform: `scale(${scale})`,
          transformOrigin,
          transition: "transform 0.2s",
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: cursorStyle,
        }}
      >
        <img
          ref={imageRef}
          src={imageUrl}
          alt="Zoomed Image"
          className="max-w-full max-h-full object-contain bg-gray-300 rounded-lg"
        />
      </div>
      <button
        className="absolute top-6 right-6 text-2xl font-bold"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <img src={Close} alt="Close" />
      </button>
    </div>
  );
};

export default ZoomedImagePopup;
