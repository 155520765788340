import React from "react";
import berainLogo from "../../assets/main-logo.png";
import "../../style.css";

interface ResponseData {
  total: number;
  page: number;
  size: number;
  items: any[];
  total_amount: string;
  total_cash_amount: string;
  total_total_mpos_card_paymentamount: string;
  total_online_payment: string;
  total_deposit_amount: string;
}

type ComponentToPrintProps = {
  data: any;
  emp: string;
  date: any;
  ref: any;
  empName: any;
  startDate?: any;
  endDate?: any;
};

const ComponentToPrint: React.FC<ComponentToPrintProps> = ({
  data,
  emp,
  date,
  ref,
  empName,
  startDate,
  endDate,
}) => {
  function formatDateTime(isoString: string) {

    if(!isoString ){
      return
    }
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${hours}:${minutes} ${ampm}`;
  }

  function convertDateToDDMMYYYY(dateString: string) {
    if(!dateString){
      return
    }
    const [year, month, day] = dateString?.split("-");
    return `${day}-${month}-${year}`;
  }

  return (
    <div id="print-content" className="hidden print:block">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col gap-2 text-8">
          <p>Date: {date}</p>
          <img src={berainLogo} alt="Logo" className="h-12" />
        </div>
        <div className="text-center">
          <h1 className="text-2xl font-bold">Deposit Summary Report</h1>
        </div>
        <div className="max-w-[50px]">
          <p className="text-sm">
            <b>Employee ID:</b> {emp}
          </p>
          <p className="text-sm">
            <b>Employee Name:</b> {empName}
          </p>
        </div>
      </div>

      <div className="text-right mb-6">
        {startDate === endDate ? (
          <p>Date: {convertDateToDDMMYYYY(startDate)}</p>
        ) : (
          <p>
            Date: {convertDateToDDMMYYYY(startDate)} to{" "}
            {convertDateToDDMMYYYY(endDate)}
          </p>
        )}
      </div>

      <table className="w-full mb-6 border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              Date & Time
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              Customer ID
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              Sap ID
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              Payment Type
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              SAP Payment reference
            </th>
            <th className="border border-gray-300 p-2 bg-gray-100 text-left">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item: any, index: number) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2">
                {formatDateTime(item.date_time) || "-"}
              </td>
              <td className="border border-gray-300 p-2">
                {item.customer_id || "-"}
              </td>
              <td className="border border-gray-300 p-2">
                {item.sap_external_id || "-"}
              </td>
              <td className="border border-gray-300 p-2">
                {item.p_source || "-"}
              </td>
              <td className="border border-gray-300 p-2">
                {item.payment_reference || "-"}
              </td>
              <td className="border border-gray-300 p-2">
                {item.amount || "-"}
              </td>
            </tr>
          ))}
          <tr>
            <td className="border border-gray-300 p-2"></td>
            <td className="border border-gray-300 p-2"></td>
            <td className="border border-gray-300 p-2"></td>
            <td className="border border-gray-300 p-2"></td>
            <td className="border border-gray-300 p-2 font-bold">
              Total Amount:
            </td>
            <td className="border border-gray-300 p-2">{data?.total_amount}</td>
          </tr>
        </tbody>
      </table>

      <div className="flex flex-col items-end font-bold space-y-2">
        <p>Total Cash Amount: {data?.total_cash_amount}</p>
        <p>
          Total Mpos Card Payment: {data?.total_total_mpos_card_paymentamount}
        </p>
        <p>Total Online Payment: {data?.total_online_payment}</p>
        <p>Total Deposit Amount: {data?.total_deposit_amount}</p>
      </div>

      {/* Signatures */}
      <div className="flex justify-between pt-24">
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          <p>Accountant's Signature</p>
        </div>
        <div className="w-1/2 text-center border-t border-gray-300 pt-4">
          <p>Salesman's Signature</p>
        </div>
      </div>
    </div>
  );
};

export default ComponentToPrint;
