import React, { useEffect, useRef, useState } from "react";
import DownArrow from "../assets/down-arrow.svg";
import UpArrow from "../assets/up-arrow.svg";

interface DropdownProps {
  label: string;
  options: { value: string | number; label: string }[];
  selectedValue: string | number;
  onChange: (value: string | number) => void;
  placeholder?: string;
  searchable?: boolean; 
  disabled?: boolean; 
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  selectedValue,
  onChange,
  placeholder = "Select an option",
  searchable = false,
  disabled = false 
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (value: string | number) => {
    onChange(value);
    setIsDropdownOpen(false);
    setSearchTerm(""); 
  };

  const optionsWithDefault = [
    { value: null, label: "Select" }, 
    ...options,
  ];

  const filteredOptions = searchable
    ? optionsWithDefault.filter((option) =>
        option?.label?.toLowerCase().startsWith(searchTerm.toLowerCase())
      )
    : optionsWithDefault;

  return (
    <div ref={dropdownRef} className={`relative w-full ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}>
      <label className="block text-10 text-gray-500 pb-1">{label}</label>
      <div
        className="h-10 rounded pt-0 py-2 bg-white flex justify-between items-center cursor-pointer border-2 border-gray-400 pt-2 px-2 " 
        onClick={() => {
          if (!disabled) {
            setIsDropdownOpen(!isDropdownOpen);
          }
        }}
      >
        <span className={`text-16 font-medium overflow-hidden text-ellipsis whitespace-nowrap`}>
          {selectedValue
            ? options.find((option) => option.value === selectedValue)?.label
            : placeholder}
        </span>
        <span className="text-16">
          <img src={isDropdownOpen ? UpArrow : DownArrow} alt="" />
        </span>
      </div>
      {isDropdownOpen && !disabled && (
        <div
          className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10"
          style={{ maxHeight: "200px", overflowY: "auto" }}
        >
          {/* Optional Search Input */}
          {searchable && (
            <div className="sticky top-0 p-1 border-b border-gray-200">
              <input
                type="text"
                placeholder="Search..."
                className=" w-full p-1 text-13 border rounded"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          )}

          {/* Options */}
          <ul className="py-1">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option:any) => (
                <li key={option.value}>
                  <button
                    onClick={() => handleOptionClick(option.value)}
                    className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 ${
                      selectedValue === option.value
                        ? "font-semibold bg-gray-100"
                        : ""
                    }`}
                  >
                    {option.label}
                  </button>
                </li>
              ))
            ) : (
              <li>
                <span className="block px-4 py-2 text-sm text-gray-500">
                  No matching options
                </span>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
