import React from "react";
import GaugeChart from "react-gauge-chart";
import { formatNumberWithoutFraction } from "../utility";
interface GaugeChartProps {
  target?: number | any;
  served?: number | any;
  pending?: number | string;
  value?: number | string;
  labelValue?: any;
}

const CustomGaugeChart: React.FC<GaugeChartProps> = ({
  served,
  pending,
  target,
  value,
  labelValue,
}) => {
  const generateColors = (value: number, levels: number) => {
    const greenSegments = Math.round(value * levels);
    const redSegments = levels - greenSegments;

    if (!greenSegments && !redSegments) {
      return ["#00C48C", "#FF4D68"];
    }
    return [
      ...Array(greenSegments)?.fill("#00C48C"), // Green till value
      ...Array(redSegments)?.fill("#FF4D68"), // Red for the rest
    ];
  };
  const colors = generateColors(Number(value) > 1 ? 1 : Number(value), 50);
  return (
    <>
      <GaugeChart
        id="gauge-chart"
        nrOfLevels={50}
        percent={Number(value) > 1 ? 1 : Number(value)}
        arcPadding={0.02}
        cornerRadius={3}
        needleColor="#345243"
        needleBaseColor="#464A4F"
        animate={false}
        textColor="black"
        hideText={true}
        colors={colors}
        // formatTextValue={() => ` ${Number(labelValue)} `}
      />
      <div className="w-[80%] font-bold flex justify-between ">
        <span>0</span>
        <span>{formatNumberWithoutFraction(Number(labelValue))} </span>{" "}
        <span style={{ float: "right" }}>
          {formatNumberWithoutFraction(Number(target))}
        </span>
      </div>
    </>
  );
};

export default CustomGaugeChart;
