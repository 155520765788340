import axios from "./config";
import { getHeader } from "../utility/index";
interface ApiResponse {
  data: any;
}

const getSliderImages = async (): Promise<ApiResponse> => {
  const response = await axios.get<ApiResponse>(`/supervisors/slider/images`, {
    headers: getHeader(),
  });
  return response;
};
export default getSliderImages;
