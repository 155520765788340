import React, { useEffect } from "react";
import { formatDate } from "../utility";
import { useTranslation } from "react-i18next";
interface TimeFrameSelectorProps {
  timeFrame: string;
  customClass?: string;
  setSelectTimeFrame: (value: string) => void;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}

const TimeFrameSelector: React.FC<TimeFrameSelectorProps> = ({
  timeFrame,
  setSelectTimeFrame,
  setStartDate,
  setEndDate,
  customClass,
}) => {
  useEffect(() => {
    if (timeFrame === "Today") {
      setStartDate(formatDate(new Date()));
      setEndDate(formatDate(new Date()));
    } else if (timeFrame === "Yesterday") {
      setStartDate(formatDate(new Date().setDate(new Date().getDate() - 1)));
      setEndDate(formatDate(new Date().setDate(new Date().getDate() - 1)));
    } else if (timeFrame === "Month Till Date") {
      setStartDate(formatDate(new Date().setDate(1)));
      setEndDate(formatDate(new Date()));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame]);
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-1">
        <label className="block text-10 text-gray-700">{t("timeframeselector.timeframe")}</label>
        <div className="flex gap-2 justify-between border-gray-400 border  rounded px-2 py-2 bg-[#F3E6F4]">
          <button
            className={`px-4 text-16 py-2 rounded transition duration-200 hover:bg-gray-300 ${
              timeFrame === "Yesterday" ? "bg-white text-purple shadow" : "text-black"
            }`}
            onClick={() => setSelectTimeFrame("Yesterday")}
          >
            {t("timeframeselector.yesterday")}
          </button>
          <button
            className={`px-4 py-2 text-16  rounded transition text-black duration-200 hover:bg-gray-300 ${
              timeFrame === "Today" ? "bg-white text-purple shadow" : "text-black"
            }`}
            onClick={() => setSelectTimeFrame("Today")}
          >
            {t("timeframeselector.today")}
          </button>
          <button
            className={`px-3 text-14  py-2 text-black rounded transition duration-200 hover:bg-gray-300 ${
              timeFrame === "Month Till Date"
                ? "bg-white text-purple shadow"
                : "text-black"
            }`}
            onClick={() => setSelectTimeFrame("Month Till Date")}
          >
            {t("timeframeselector.monthtilldate")}
          </button>
        </div>

        {/* <select
          className={`text-base font-medium  ${customClass}`}
          value={timeFrame}
          onChange={(e) => setSelectTimeFrame(e.target.value)}
        >
          <option value="">select</option>
          <option value="">Today</option>
          <option value="Yesterday">Yesterday</option>
          <option value="Month till date">Month till date</option>
        </select> */}
      </div>
    </>
  );
};

export default TimeFrameSelector;
