import React from "react";
import { ResponsiveBar } from "@nivo/bar";

interface BarChartProps {
  data: any; 
  barColors: string[]; // Assuming barColors is an array of strings representing colors
}

const BarChart: React.FC<BarChartProps> = ({ data, barColors }) => {


  const values = data?.map((d: any) => d.value);

  if (values.every((value:any) => value === 0)) {
    return <div></div>; 
  }

  const maxValue = Math.max(...values);

  const calculateStepSize = (maxValue: number) => {
    const range = maxValue; 
    const magnitude = Math.pow(10, Math.floor(Math.log10(range))); // Base magnitude
    const normalizedRange = range / magnitude;
    let step;

    if (normalizedRange <= 1) {
      step = 0.2;
    } else if (normalizedRange <= 2) {
      step = 0.5;
    } else if (normalizedRange <= 5) {
      step = 1;
    } else {
      step = 2;
    }

    return step * magnitude;
  };

  const stepSize = calculateStepSize(maxValue);
  const tickValues = [];
  for (let i = 0; i <= maxValue + stepSize; i += stepSize) {
    tickValues.push(Number(i.toFixed(2)));
  }
  
  return (
    <div style={{ height: "200px" }}>
      <ResponsiveBar
        data={data}
        keys={["value"]}
        indexBy="visits"
        margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
        padding={0.6}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={({ index }) => barColors[index]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Visits",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        enableLabel={false}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: "Value",
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: tickValues,
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 12, 
                fontWeight: 600, 
              },
            },

          },
        }}
        enableGridX={false}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        label={() => ""}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={
          [
            // Uncomment this if you want legends
            // {
            //   dataFrom: "keys",
            //   anchor: "bottom-right",
            //   direction: "column",
            //   justify: false,
            //   translateX: 120,
            //   translateY: 0,
            //   itemsSpacing: 2,
            //   itemWidth: 100,
            //   itemHeight: 20,
            //   itemDirection: "left-to-right",
            //   itemOpacity: 0.85,
            //   symbolSize: 20,
            //   effects: [
            //     {
            //       on: "hover",
            //       style: {
            //         itemOpacity: 1,
            //       },
            //     },
            //   ],
            // },
          ]
        }
        animate={true}
      />
    </div>
  );
};

export default BarChart;
