import React, { useEffect, useState, useRef, useCallback } from "react";
import DashboardPage from "../../layout";
import { salesSummary } from "../../api/reports";
import PaginationComponent from "../../components/Pagination";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getUserInfo,
  getPagePerSize,
} from "../../utility";
import { getGlobalFilter } from "../../api/filters";
import Export from "../../assets/icons/Export.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import { formatDate } from "../../utility";
import Close from "../../assets/close-circle.svg";
import Dropdown from "../../components/Dropdown";
import Search from "../../assets/Search.svg";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import ComponentToPrint from "./ComponentPrint";
import { toast } from "react-toastify";
import moment from "moment";
import DropdownMulti from "../../components/DropdownMultiple";
import debounce from "lodash/debounce";

const CustomerTable: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const customersPerPage = getPagePerSize();
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [customers, setCustomersData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [printableData, setPrintableData] = useState();
  const printRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [region, setRegion] = useState<any>([]);
  const [emp, setEmployee] = useState<any>("");
  const [empName, setEmpName] = useState<any>("");
  const [paymentType, setPaymentType] = useState<any>("");
  const [channel, setChannel] = useState<any>([]);
  const [teretorryId, setTeretorryId] = useState<any>([]);
  const [loadingFilter, setFilterLoading] = useState<boolean>(false);
  const [salesOffice, setSalesOffice] = useState<any>([]);
  const [routeID, setRouteID] = useState<any>([]);
  const [sort, setSort] = useState<any>("");
  const [customerId, setCustomerID] = useState<any>([]);
  const startIndex = (currentPage - 1) * customers?.size;

  useEffect(() => {
    setEmpName(
      globalFilters?.employees?.filter((e: any) => emp === e.id)[0]?.name_eng
    );
  }, [emp]);

  const handleFilterClick = () => {
    setOpen(true);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getGlobalFilters = async (data: any) => {
    setFilterLoading(true);
    try {
      if (loadingFilter) {
        return;
      }
      const response = await getGlobalFilter(data);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    } finally {
      setFilterLoading(false);
    }
  };

  const handleClearFilters = () => {
    setEmployee(null);
    setPaymentType(null);
    setRegion([]);
    setRouteID([]);
    setChannel([]);
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setOpen(false);
    setSelectTimeFrame("Today");
    setCustomerID([]);
    handleClearApply("");
  };

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (region.length > 0) {
      data["regions"] = region;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp.length > 0) {
      data["subordinate_ids"] = [emp];
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }

    data["endpoint"] = "sales-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };
  const handleClearApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    const params = {
      page: 1,
    };
    setCurrentPage(1);
    getCustomerData(data, params);
    handleClose();
  };

  const handleChange = (event: any) => {
    updateSearchTerm(event);
    setSearchQuery(event);
  };

  const updateSearchTerm = useCallback(
    debounce((value: string) => {
      handleSearch(value);
    }, 500),
    [
      channel,
      paymentType,
      customerId,
      region,
      routeID,
      salesOffice,
      emp,
      teretorryId,
      sort,
      startDate,
      endDate,
    ]
  );

  const handleSearch = (value: any) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (value) {
      data["search"] = value;
    }
    getPayload(data);
    const params = {
      page: 1,
      size: customersPerPage,
    };
    setCurrentPage(1);
    getCustomerData(data, params);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };

  const handleClose = () => setOpen(false);

  const getCustomerData = async (data: any, params: any) => {
    setCustomersData([]);
    setLoading(true);
    await salesSummary(params, data)
      .then((res) => {
        setLoading(false);
        console.log(res.data, "data");
        setCustomersData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer data:", error);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    getPayload(data);

    const params = {
      page: currentPage,
      size: customersPerPage,
    };
    getCustomerData(data, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const getPayload = (data: any) => {
    if (channel.length > 0) {
      data["channel_ids"] = channel;
    }
    if (paymentType) {
      data["payment_type"] = paymentType;
    }
    if (customerId.length > 0) {
      data["customer_ids"] = customerId;
    }
    if (region.length > 0) {
      data["regions"] = region;
    }
    if (routeID.length > 0) {
      data["route_ids"] = routeID;
    }
    if (salesOffice.length > 0) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (sort) {
      data["sort"] = [sort];
    }
  };

  const handleApply = (sort: string) => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    getPayload(data);
    const params = {
      page: 1,
      size: customersPerPage,
    };
    setCurrentPage(1);
    getCustomerData(data, params);
    handleClose();
  };

  useEffect(() => {
    const data: any = {};

    if (region) {
      data["regions"] = region;
    }
    if (salesOffice) {
      data["sales_office_ids"] = salesOffice;
    }
    if (emp) {
      data["subordinate_ids"] = [emp];
    }
    if (teretorryId) {
      data["territory_ids"] = teretorryId;
    }
    data["endpoint"] = "sales-summary";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
  }, []);

  const printData = async () => {
    if (!emp) {
      return toast.warn("Please Select Employee");
    }
    const user_info = getUserInfo();
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      emp_id: user_info.emp_id,
    };

    if (emp) {
      data["is_print"] = true;
    }
    getPayload(data);

    const params = {
      page: currentPage,
      size: customersPerPage,
    };
    await salesSummary(params, data)
      .then((res) => {
        setLoading(false);
        setPrintableData(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching Customer data:", error);
      });
  };

  useEffect(() => {
    if (!printableData) {
      return;
    }
    const printContent = document.getElementById("print-content");
    if (!printContent) return;

    const newWindow = window.open("", "_blank", "width=800,height=600");
    if (newWindow) {
      printContent.classList.remove("hidden");
      printContent.classList.add("block");
      const link = newWindow.document.createElement("link");
      link.href =
        "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css";
      link.rel = "stylesheet";
      newWindow.document.head.appendChild(link);

      link.onload = () => {
        newWindow.document.body.innerHTML = `
        <html>
          <head>
            <title>Print</title>
          </head>
          <body>${printContent.outerHTML}</body>
        </html>
      `;

        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
        printContent.classList.remove("block");
        printContent.classList.add("hidden");
      };
    }
  }, [printableData]);

  useEffect(() => {
    setSalesOffice("");
  }, [region]);

  useEffect(() => {
    setTeretorryId("");
  }, [salesOffice]);

  useEffect(() => {
    setEmployee("");
    setChannel("");
    setRouteID("");
    setCustomerID("");
  }, [teretorryId]);

  return (
    <DashboardPage>
      <div className="bg-gray-50  sm:pl-0 ">
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2 pr-8">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={customers?.total}
              itemsPerPage={customers?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("masterList.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={t("DepositInvoice.serachCusid")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>
            <div className="hidden sm:flex space-x-4 items-center relative">
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("masterList.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer  ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("masterList.descending")}
                  </li>
                </ul>
              )}
              <button
                onClick={handleFilterClick}
                className="flex items-center gap-2 px-4 py-2 text-13  rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("masterList.filter")}
                  className="w-4 h-4 "
                />
                {t("masterList.filter")}
              </button>
              <div></div>
              <button
                onClick={printData}
                className="flex items-center px-4 py-2 text-13 font-medium border rounded transition-all"
              >
                {t("DepositInvoice.print")}
                <img
                  src={Export}
                  alt={t("masterList.export")}
                  className="w-4 h-4 ml-2"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="relative w-full sm:w-auto">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              {/* Add your search icon here */}
              <img src={Search} alt={t("masterList.searchPlaceholder")} />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t("masterList.searchPlaceholder")}
              className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={printData}
              className="px-2 py-2 text-gray-600 rounded transition-all"
            >
              <img
                src={Export}
                alt={t("masterList.export")}
                className="w-4 h-4"
              />
            </button>
            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="relative bg-white shadow-lg w-full max-w-md h-full">
              <div className="absolute z-30 top-[50%] left-[45%]">
                {loadingFilter && <Loader />}
              </div>
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("masterList.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt={t("masterList.filters.title")}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto scrollbar scrollbar-thin">
                <div className="flex flex-col gap-[30px]">
                  {/* Employee ID */}
                  <div className="flex flex-col sm:flex-row gap-4 justify-between">
                    <div className="w-full">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={region}
                        onClose={handleFiltersUpdate}
                        onChange={setRegion}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-full">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={salesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSalesOffice}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={teretorryId}
                        onChange={setTeretorryId}
                        onClose={handleFiltersUpdate}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={channel}
                        onClose={handleFiltersUpdate}
                        onChange={setChannel}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.routeID")}
                        options={
                          globalFilters?.routes?.map((emp: any) => ({
                            value: `${emp.route_id}`,
                            label: emp.route_identifier,
                          })) || []
                        }
                        selectedValues={routeID}
                        onClose={handleFiltersUpdate}
                        onChange={setRouteID}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectRouteID")}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValue={emp}
                        onChange={setEmployee}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("activityreports.filters.selectCustomerId")}
                        options={
                          globalFilters?.customers?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={customerId}
                        onChange={setCustomerID}
                        placeholder={t(
                          "activityreports.filters.selectCustomerId"
                        )}
                        searchable={true}
                        disabled={region.length === 0 || loadingFilter}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label={t("DepositInvoice.paymentType")}
                        options={
                          globalFilters?.deposit_summary_payment_types?.map(
                            (emp: any) => ({
                              value: emp,
                              label: emp,
                            })
                          ) || []
                        }
                        selectedValue={paymentType}
                        onChange={setPaymentType}
                        disabled={region.length === 0 || loadingFilter}
                        placeholder={t("DepositInvoice.paymentType")}
                      />
                    </div>
                  </div>
                  <div className="date-range-picker flex gap-4">
                    <div className="flex gap-4 w-full">
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.startDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={startDate}
                          max={new Date(endDate).toISOString().split("T")[0]}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-500 pb-1">
                          {t("masterList.filters.endDate")}
                        </label>
                        <input
                          type="date"
                          className="rounded p-2 border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <Button
                    onClick={handleClearFilters}
                    label={t("masterList.filters.clearFilters")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("masterList.filters.applyFilters")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          // id="printable-area"
          className="h-[calc(100vh-200px)] md:h-[calc(100vh-120px)] p-2 pt-0 overflow-auto  page-container scrollbar scrollbar-thin "
          style={{
            overflow: customers?.items?.length === 0 ? "hidden" : "auto",
          }}
        >
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("activityreports.tableHeaders.srno")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("DepositInvoice.employeeName")}
                </th>

                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.datetime")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.customerid")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.transactionNumber")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.orderType")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.netprice")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.discountAmount")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.netvalue")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.vat")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.totalvat")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.paymentType")}
                </th>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("salesReport.amountPaid")}
                </th>
              </tr>
            </thead>
            <tbody>
              {customers?.items?.length > 0 &&
                !loading &&
                customers.items.map((customer: any, index: number) => (
                  <tr key={customer.emp_id} className="hover:bg-gray-50">
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {startIndex + index + 1}
                    </td>

                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.employee_name || "-"}
                    </td>

                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {moment(customer.date_time).format(
                        "DD-MM-YYYY , hh:mm A"
                      )}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.customer_id || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.transaction_no || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.order_type || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.net_price || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.discount_amount || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.net_value || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.vat || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.total_after_vat || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.payment_type || "-"}
                    </td>
                    <td className="px-4 py-3 border text-black-600 whitespace-nowrap">
                      {customer.amount_paid || "-"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {customers?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("masterList.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
        </div>
        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={customers?.total}
            itemsPerPage={customers?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <div>
        <ComponentToPrint
          data={printableData}
          ref={printRef}
          emp={emp}
          empName={empName}
          date={moment.utc(new Date()).local().format("DD-MM-YYYY hh:mm A")}
          startDate={formatDate(startDate)}
          endDate={formatDate(endDate)}
        />
      </div>
    </DashboardPage>
  );
};

export default CustomerTable;
