import React, { useEffect, useState, useCallback } from "react";
import DashboardPage from "../../layout";
import getPlanogramList from "../../api/planogram";
import PaginationComponent from "../../components/Pagination";
import Export from "../../assets/icons/Export.svg";
import Sort from "../../assets/icons/Sort.svg";
import Eye from "../../assets/eye.svg";
import Filter from "../../assets/icons/Filter.svg";
import Loader from "../../components/Loader";
import { getGlobalFilter } from "../../api/filters";

import PlanogramPopup from "../../components/PlanogramPopup";
import { useTranslation } from "react-i18next";
import TimeFrameSelector from "../../components/TimeFrameSelector";
import {
  formatCurrentEndDate,
  formatCurrentStartDate,
  TimeZoneDisplay,
  getPagePerSize,
} from "../../utility";
import { formatDate } from "../../utility";
import moment from "moment";
import Dropdown from "../../components/Dropdown";
import Close from "../../assets/close-circle.svg";
import Search from "../../assets/Search.svg";
import Button from "../../components/Button";
import DropdownMulti from "../../components/DropdownMultiple";
import debounce from "lodash/debounce";

const Planogram: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const employeesPerPage = getPagePerSize();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [MobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<any>([]);
  const [selectedSalesOffice, setSelectedSalesOffice] = useState<any>([]);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState<any>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>([]);
  const [selectedImageType, setSelectedImageType] = useState("");
  const [timeFrame, setSelectTimeFrame] = useState<any>("Today");
  const [open, setOpen] = useState(false);
  const [planogram, setPlanogramData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [globalFilters, setGlobalFilters] = useState<any>(null);
  const [channel, setChannel] = useState<any>([]);
  const [route, setRoute] = useState<any>([]);
  const [teretorryId, setTeretorryId] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [status, setStatus] = useState<any>("");
  const [imgData, setImgData] = useState<any>("");
  const [sortType, setSortType] = useState<any>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loadingFilter, setFilterLoading] = useState(false);
  const startIndex = (currentPage - 1) * planogram?.size;

  const handleOpenPopup = (data: any) => {
    setIsPopupOpen(true);
    setImgData(data);
  };

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getGlobalFilters = async (data: any) => {
    setFilterLoading(true);
    try {
      const response = await getGlobalFilter(data);
      setGlobalFilters(response);
    } catch (error) {
      console.error("Error fetching global filters:", error);
    } finally {
      setFilterLoading(false);
    }
  };

  const handleClearFilters = () => {
    setSelectedEmployeeID([]);
    setSelectedCustomerIds([]);
    setSelectedSalesOffice([]);
    setSelectedRegion([]);
    setSelectedImageType("");
    setStartDate(getCurrentDate());
    setEndDate(getCurrentDate());
    setStatus(null);
    setSelectTimeFrame("Today");
    setOpen(false);
    setRoute([]);
    setTeretorryId([]);
    handleClearApply("");
  };

  const handleClearApply = (sort: string): void => {
    // Prepare filters object to send as payload
    const data: {
      start_date: string;
      end_date: string;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    const params = {
      page: 1,
      size: employeesPerPage,
    };
    setCurrentPage(1);
    getPlanogramData(params, data);
    setOpen(false);
  };

  const handleChange = (event: any) => {
    updateSearchTerm(event);
    setSearchQuery(event);
  };

  const updateSearchTerm = useCallback(
    debounce((value: string) => {
      handleSearch(value);
    }, 500),
    [
      selectedRegion,
      teretorryId,
      channel,
      route,
      selectedSalesOffice,
      selectedImageType,
      selectedCustomerIds,
      selectedEmployeeID,
      status,
      sortType,
      startDate,
      endDate,
    ]
  );

  const handleSearch = async (value: string) => {
    setCurrentPage(1);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (value) {
      data["search"] = value;
    }
    await getPayLoad(data);

    const params = {
      page: 1,
      size: employeesPerPage,
    };
    setCurrentPage(1);
    getPlanogramData(params, data);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFilterClick = () => {
    setOpen(true);
  };

  const handleSort = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getPlanogramData = async (params: any, data: any = {}) => {
    setLoading(true);
    await getPlanogramList(params, data)
      .then((res) => {
        setPlanogramData(res);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching employee data:", error);
      });
  };

  const getPayLoad = (data: any) => {
    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (channel.length > 0) {
      data["channel_ids"] = channel;
    }
    if (route.length > 0) {
      data["route_ids"] = route;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedImageType) {
      data["image_type"] = selectedImageType;
    }
    if (selectedCustomerIds.length > 0) {
      data["customer_ids"] = selectedCustomerIds;
    }
    if (selectedEmployeeID.length > 0) {
      data["subordinate_ids"] = selectedEmployeeID;
    }
    if (status) {
      data["planogram_status"] = status;
    }
    if (sortType) {
      data["sort_by"] = sortType;
    }
  };
  useEffect(() => {
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };
    getPayLoad(data);
    const params = {
      page: currentPage,
      size: employeesPerPage,
    };

    if (startDate && endDate) {
      getPlanogramData(params, data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleFiltersUpdate = () => {
    const data: any = {};

    if (selectedRegion.length > 0) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice.length > 0) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmployeeID.length > 0) {
      data["subordinate_ids"] = selectedEmployeeID;
    }
    if (teretorryId.length > 0) {
      data["territory_ids"] = teretorryId;
    }
    if (route.length > 0) {
      data["route_ids"] = route;
    }
    data["endpoint"] = "planogram";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));
    console.log("Filters Updated:", data);
    getGlobalFilters(data);
  };

  useEffect(() => {
    const data: any = {};

    if (selectedRegion) {
      data["regions"] = selectedRegion;
    }
    if (selectedSalesOffice) {
      data["sales_office_ids"] = selectedSalesOffice;
    }
    if (selectedEmployeeID) {
      data["subordinate_ids"] = selectedEmployeeID;
    }
    if (teretorryId) {
      data["territory_ids"] = teretorryId;
    }
    data["endpoint"] = "planogram";
    data["start_date"] = formatCurrentStartDate(new Date(startDate));
    data["end_date"] = formatCurrentEndDate(new Date(endDate));

    getGlobalFilters(data);
  }, []);

  const handleApply = (sort: string): void => {
    // Prepare filters object to send as payload
    setCurrentPage(1);
    const data: {
      start_date: string;
      end_date: string;
      planogram_status?: string;
      image_type?: any;
      channel_ids?: Array<any>;
      regions?: Array<any>;
      sales_office_ids?: Array<any>;
      route_ids?: Array<any>;
      territory_ids?: Array<any>;
      customer_ids?: any;
      subordinate_ids?: any;
      sort_by?: any;
      search?: any;
    } = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    if (sort) {
      data["sort_by"] = sort;
    }
    getPayLoad(data);
    const params = {
      page: 1,
      size: employeesPerPage,
    };

    getPlanogramData(params, data);
    setOpen(false);
  };

  const checkTimeFrame = (start: any, end: any) => {
    if (start === formatDate(new Date()) && end === formatDate(new Date())) {
      setSelectTimeFrame("Today");
    } else if (
      start === formatDate(new Date().setDate(new Date().getDate() - 1)) &&
      end === formatDate(new Date().setDate(new Date().getDate() - 1))
    ) {
      setSelectTimeFrame("Yesterday");
    } else if (
      start === formatDate(new Date().setDate(1)) &&
      end === formatDate(new Date())
    ) {
      setSelectTimeFrame("Month till date");
    } else {
      setSelectTimeFrame("");
    }
  };

  const handleExport = async () => {
    setLoading(true);
    const data: any = {
      start_date: formatCurrentStartDate(new Date(startDate)),
      end_date: formatCurrentEndDate(new Date(endDate)),
      export: true,
      time_zone: TimeZoneDisplay(),
    };

    if (searchQuery) {
      data["search"] = searchQuery;
    }
    getPayLoad(data);

    const params = {};
    try {
      await exportActiviyData(data, params);
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportActiviyData = async (data: any, params: any) => {
    await getPlanogramList(params, data)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `planogram_report.csv`;
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    checkTimeFrame(e.target.value, endDate);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    checkTimeFrame(startDate, e.target.value);
  };

  const handleSortFillter = (data: string) => {
    setSortType(data);
    setIsDropdownOpen(false);
    handleApply(data);
  };
  useEffect(() => {
    setSelectedSalesOffice([]);
    setTeretorryId([]);
    setRoute([]);
    setSelectedEmployeeID([]);
  }, [selectedRegion]);

  useEffect(() => {
    setTeretorryId([]);
    setRoute([]);
    setSelectedEmployeeID([]);
  }, [selectedSalesOffice]);

  useEffect(() => {
    setRoute([]);
    setSelectedEmployeeID([]);
    setChannel([]);
  }, [teretorryId]);

  return (
    <DashboardPage>
      <div className="bg-gray-50 flex flex-col">
        {/* Top bar for desktop */}
        <div className="hidden mb-2 sm:flex flex-col sm:flex-row justify-between items-center bg-white p-2 border-b-2">
          <div className="w-full sm:w-auto mb-4 sm:mb-0 pl-6">
            <PaginationComponent
              totalCount={planogram?.total}
              itemsPerPage={planogram?.size}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto space-y-4 sm:space-y-0 sm:space-x-4 pr-10">
            <div className="relative w-full sm:w-auto">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <img src={Search} alt={t("planogram.searchPlaceholder")} />
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={t("planogram.searchPlaceholder")}
                className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
                style={{ borderColor: "#e0e0e0" }}
              />
            </div>

            <div className="hidden sm:flex space-x-4 items-center relative">
              {isDropdownOpen && (
                <ul className="absolute z-50 right-30 top-10 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "ASC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("ASC")}
                  >
                    {t("planogram.ascending")}
                  </li>
                  <li
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${
                      sortType === "DESC" && "bg-lightgrey"
                    }`}
                    onClick={() => handleSortFillter("DESC")}
                  >
                    {t("planogram.descending")}
                  </li>
                </ul>
              )}

              <button
                onClick={handleFilterClick}
                className="flex items-center px-4 py-2 text-sm text-gray-600 rounded transition-all"
              >
                <img
                  src={Filter}
                  alt={t("planogram.filter")}
                  className="w-4 h-4 mr-2"
                />
                {t("planogram.filter")}
              </button>

              <button
                onClick={handleExport}
                className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center gap-2">
                    <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                    {t("masterList.exporting")}
                  </span>
                ) : (
                  <>
                    {t("masterList.export")}
                    <img
                      src={Export}
                      alt={t("masterList.export")}
                      className="w-4 h-4 "
                    />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Top bar for mobile */}
        <div className="sm:hidden flex justify-between items-center p-2 border-b-2 bg-white">
          <div className="relative w-full sm:w-auto">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <img src={Search} alt={t("planogram.searchPlaceholder")} />
            </div>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={t("planogram.searchPlaceholder")}
              className="w-full sm:w-auto pl-10 pr-4 py-2 text-sm border rounded text-gray-600"
              style={{ borderColor: "#e0e0e0" }}
            />
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={handleExport}
              className={`flex items-center gap-2 px-4 py-2 text-13 font-medium border rounded transition-all ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center gap-2">
                  <span className="loader w-4 h-4 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></span>
                  {t("masterList.exporting")}
                </span>
              ) : (
                <>
                  {t("masterList.export")}
                  <img
                    src={Export}
                    alt={t("masterList.export")}
                    className="w-4 h-4 "
                  />
                </>
              )}
            </button>

            <button
              className="px-2 py-2 text-gray-600 rounded transition-all"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu for Sort and Filter */}
        {MobileMenuOpen && (
          <div className="sm:hidden bg-white border-t-2">
            <button
              onClick={handleSort}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-600 border-b transition-all"
            >
              <img
                src={Sort}
                alt={t("planogram.mobileMenu.sortBy")}
                className="w-4 h-4 mr-2"
              />
              {t("planogram.mobileMenu.sortBy")}
            </button>

            <button
              onClick={handleFilterClick}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-600 border-b transition-all"
            >
              <img
                src={Filter}
                alt={t("planogram.mobileMenu.filter")}
                className="w-4 h-4 mr-2"
              />
              {t("planogram.mobileMenu.filter")}
            </button>
          </div>
        )}

        {open && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-end sm:items-start items-center z-50">
            <div className="relative bg-white shadow-lg w-full max-w-md h-full">
              <div className="absolute z-30 top-[50%] left-[45%]">
                {loadingFilter && <Loader />}
              </div>
              <div className="flex justify-between p-6 border-b-2">
                <div className="text-16 font-bold">
                  {t("planogram.filters.title")}
                </div>
                <div>
                  <img
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                    src={Close}
                    alt={t("planogram.filters.closeAlt")}
                  />
                </div>
              </div>
              <div className="flex flex-col justify-between h-[calc(100vh-100px)] px-12 pt-6 overflow-y-auto scrollbar scrollbar-thin">
                <div className="flex flex-col gap-[30px]">
                  {/* Employee Dropdown */}
                  <div className="flex flex-col sm:flex-row gap-4 justify-between">
                    <div className="w-full ">
                      <DropdownMulti
                        label={t("visitreports.filters.region")}
                        options={globalFilters?.regions.map((sales: any) => ({
                          value: sales,
                          label: sales,
                        }))}
                        selectedValues={selectedRegion}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedRegion}
                        placeholder={t("visitreports.filters.selectRegion")}
                      />
                    </div>
                    <div className="w-full ">
                      <DropdownMulti
                        label={t("visitreports.filters.salesOffice")}
                        options={
                          globalFilters?.sales_offices.map((sales: any) => ({
                            value: sales.id,
                            label: sales.name,
                          })) || []
                        }
                        selectedValues={selectedSalesOffice}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedSalesOffice}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectSalesOffice"
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.territoryID")}
                        options={
                          globalFilters?.territories?.map((emp: any) => ({
                            value: String(emp.territory_id),
                            label: emp.territory_identifier,
                          })) || []
                        }
                        selectedValues={teretorryId}
                        onChange={setTeretorryId}
                        onClose={handleFiltersUpdate}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t(
                          "visitreports.filters.selectTerritoryID"
                        )}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.channelID")}
                        options={
                          globalFilters?.channels?.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name,
                          })) || []
                        }
                        selectedValues={channel}
                        onClose={handleFiltersUpdate}
                        onChange={setChannel}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectChannelID")}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("visitreports.filters.routeID")}
                        options={
                          globalFilters?.routes?.map((emp: any) => ({
                            value: `${emp.route_id}`,
                            label: emp.route_identifier,
                          })) || []
                        }
                        selectedValues={route}
                        onClose={handleFiltersUpdate}
                        onChange={setRoute}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t("visitreports.filters.selectRouteID")}
                      />
                    </div>
                    <div className="w-1/2">
                      <DropdownMulti
                        label={t("ActivityReport.employeeName")}
                        options={
                          globalFilters?.employees.map((emp: any) => ({
                            value: emp.id,
                            label: emp.name_eng,
                          })) || []
                        }
                        selectedValues={selectedEmployeeID}
                        onClose={handleFiltersUpdate}
                        onChange={setSelectedEmployeeID}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t("ActivityReport.employeeName")}
                        searchable={true}
                      />
                    </div>
                  </div>
                  {/* Customer Dropdown */}
                  <DropdownMulti
                    label={t("planogram.filters.customer")}
                    options={globalFilters?.customers.map((emp: any) => ({
                      value: emp.id,
                      label: emp.name_eng,
                    }))}
                    selectedValues={selectedCustomerIds}
                    onChange={setSelectedCustomerIds}
                    searchable={true}
                    disabled={selectedRegion.length === 0 || loadingFilter}
                    placeholder={t("planogram.filters.allCustomers")}
                  />

                  {/* Date Range Picker */}
                  <div className="date-range-picker space-y-2 ">
                    <div className="flex flex-col justify-between md:flex-row gap-4">
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("planogram.filters.startDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2  border-2 border-gray-400 w-full"
                          value={startDate}
                          onChange={handleStartDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                          max={new Date(endDate).toISOString().split("T")[0]}
                        />
                      </div>
                      <div className="w-full">
                        <label className="block text-10 text-gray-700 mb-1">
                          {t("planogram.filters.endDate")}:
                        </label>
                        <input
                          type="date"
                          className="rounded p-2  border-2 border-gray-400 w-full"
                          value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          min={new Date(startDate).toISOString().split("T")[0]}
                          onChange={handleEndDateChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4 justify-between">
                    <div className="w-1/2">
                      <Dropdown
                        label={t("planogram.filters.planogramStatus")}
                        options={[
                          {
                            value: "in_stock",
                            label: t("planogram.filters.inStock"),
                          },
                          {
                            value: "out_of_stock",
                            label: t("planogram.filters.outOfStock"),
                          },
                        ]}
                        selectedValue={status}
                        onChange={setStatus}
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t("planogram.filters.planogramStatus")}
                      />
                    </div>
                    <div className="w-1/2 ">
                      <Dropdown
                        label={t("planogram.filters.imageType")}
                        options={
                          globalFilters?.planogram_image_types.map(
                            (img: any) => ({
                              value: img,
                              label: img,
                            })
                          ) || []
                        }
                        selectedValue={selectedImageType}
                        onChange={(value) =>
                          setSelectedImageType(value as string)
                        }
                        disabled={selectedRegion.length === 0 || loadingFilter}
                        placeholder={t("planogram.filters.imageType")}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <TimeFrameSelector
                      customClass={"w-full border border-gray-300 rounded p-2"}
                      setSelectTimeFrame={setSelectTimeFrame}
                      timeFrame={timeFrame}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-between mt-4 pb-5">
                  <Button
                    onClick={handleClearFilters}
                    label={t("planogram.filters.clearFilters")}
                    type="grey"
                  />
                  <Button
                    onClick={() => handleApply(sortType)}
                    label={t("planogram.filters.applyFilters")}
                    type="purple"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="h-[calc(100vh-200px)]  md:h-[calc(100vh-120px)] overflow-auto p-2 pt-0 page-container scrollbar scrollbar-thin ">
          <table className="min-w-full bg-white border border-black-200 table-auto border-separate border-spacing-0">
            <thead className="bg-gray-200 sticky top-[0px] z-20">
              <tr>
                <th className="px-4 py-3 border border-gray-300 text-left text-13 font-bold text-black-500 whitespace-nowrap">
                  {t("activityreports.tableHeaders.srno")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("planogram.tableHeaders.referenceID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("planogram.tableHeaders.salesOfficeID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-nowrap">
                  {t("planogram.tableHeaders.customerID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.salesmanID")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.dateOfCapture")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.timeOfCapture")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 whitespace-normal w-16 sm:w-24">
                  {t("planogram.tableHeaders.sku200ml")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku330ml")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku500ml")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku600ml")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku1_5Ltr")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku5Ltr")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku12Ltr")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku270mlGlass")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku750mlGlass")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku270mlCarbonated")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.sku750mlCarbonated")}
                </th>
                <th className="px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500">
                  {t("planogram.tableHeaders.totalSKU")}
                </th>
                <th className="sticky right-0 bg-gray-200 px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 tracking-wider z-4">
                  {t("planogram.tableHeaders.imageType")}
                </th>
                <th className="sticky right-0 bg-gray-200 px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 tracking-wider z-4">
                  {t("planogram.tableHeaders.planogramStatus")}
                </th>
                <th className="sticky right-0 bg-gray-200 px-2 sm:px-4 py-3 border border-gray-300 text-left text-xs sm:text-sm font-bold text-black-500 tracking-wider z-4">
                  {t("planogram.tableHeaders.viewImage")}
                </th>
              </tr>
            </thead>
            <tbody>
              {planogram?.items?.length > 0 &&
                !loading &&
                planogram?.items?.map((planogram: any, index: number) => (
                  <tr
                    key={planogram.items}
                    className="text-xs sm:text-sm hover:bg-gray-50"
                  >
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {startIndex + index + 1}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.reference_id}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.sales_office_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.customer_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.salesman_id || "N/A"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {moment
                        .utc(planogram.date_of_capture)
                        .local()
                        .format("DD-MM-YYYY")}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {moment
                        .utc(planogram.time_of_capture, "HH:mm:ss.SSSSSS")
                        .local()
                        .format("hh:mm A")}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_200_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_330_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_500_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_600_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_1_5_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_5_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ltr_12_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_270_glass_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_750_glass_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_270_carbonated_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.ml_750_carbonated_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.total_sku_count || 0}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.image_type || "-"}
                    </td>
                    <td className="px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap">
                      {planogram.planogram_status === "in_stock" && "In Stock"}
                      {planogram.planogram_status === "out_of_stock" &&
                        "Out Of Stock"}
                    </td>
                    <td className="sticky right-0 bg-white px-2 sm:px-4 py-3 border text-black-600 whitespace-nowrap  flex items-center justify-center ">
                      <img
                        src={Eye}
                        onClick={() => {
                          handleOpenPopup(planogram);
                        }}
                        alt="Action Item"
                        className="h-5 w-5 sm:h-6 sm:w-6 cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {planogram?.items?.length === 0 && !loading && (
            <>
              <div className="px-4 py-3 border text-center text-black-600 w-full flex items-center justify-center">
                {t("planogram.noresultsfound")}
              </div>
            </>
          )}
          {loading && <Loader />}
          {isPopupOpen && (
            <PlanogramPopup
              actualImage={imgData?.actual_image_path}
              processedImage={imgData?.processed_image_path}
              comparisonImage={imgData?.processed_image_path}
              imageType={imgData?.image_type}
              onClose={handleClosePopup}
            />
          )}
        </div>

        <div className="sm:hidden bg-white p-2 border-t-2 mt-auto">
          <PaginationComponent
            totalCount={planogram?.total}
            itemsPerPage={planogram?.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </DashboardPage>
  );
};

export default Planogram;
