import axios from "./config";
import { getHeader, getUserInfo } from "../utility/index";

const getGlobalFilter = async (data: any): Promise<any> => {
  const user_info = getUserInfo();

  const response = await axios.post<any>(
    `/supervisors/global-filters`,data,
    {
      headers: getHeader(),
    }
  );
  return response.data;
};

export { getGlobalFilter };
