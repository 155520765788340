import React, { useState } from "react";
import Close from "../assets/close-circle.svg";
import ZoomedImagePopup from "./ZoomedImagePopup"; // Import the new ZoomedImagePopup component

interface ImageComparisonProps {
  actualImage: string;
  processedImage: string;
  comparisonImage: string;
  imageType: string;
  onClose: () => void;
}

const PlanogramPopup: React.FC<ImageComparisonProps> = ({
  actualImage,
  processedImage,
  onClose,
  imageType,
}) => {
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [imageToZoom, setImageToZoom] = useState<string | null>(null);

  const openZoomedImage = (imageUrl: string) => {
    setIsZoomedIn(true);
    setImageToZoom(imageUrl);
  };

  const closeZoomedImage = () => {
    setIsZoomedIn(false);
    setImageToZoom(null);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg p-12 max-w-6xl w-auto h-full md:h-[700px] overflow-auto md:overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center pb-6 border-b">
          <h2 className="text-20 font-bold">Image Comparison</h2>
          <button className="text-3xl font-bold" onClick={onClose}>
            <img src={Close} alt="Close" />
          </button>
        </div>
        <div className="py-4">
          <h3 className="mb-2 text-16 font-bold text-left">
            Image Type : {imageType}
          </h3>
        </div>
        <div className="flex justify-between w-full gap-6 flex-col md:flex-row">
          <div className="text-center flex-1">
            <h3 className="mb-4 text-16 font-bold text-left">Actual Image :</h3>
            <div className="relative">
              <img
                src={actualImage}
                alt="Actual"
                className="w-full h-[450px] md:w-[500px] md:h-[450px] object-cover bg-gray-300 rounded-lg cursor-pointer"
                onClick={() => openZoomedImage(actualImage)}
              />
            </div>
          </div>
          {processedImage && (
            <div className="text-center flex-1">
              <h3 className="mb-4 text-16 font-bold text-left">
                Processed Image :
              </h3>
              <div className="relative">
                <img
                  src={processedImage}
                  alt="Processed"
                  className="w-full h-[450px] md:w-[500px] md:h-[450px] object-cover bg-gray-300 rounded-lg cursor-pointer"
                  onClick={() => openZoomedImage(processedImage)}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isZoomedIn && imageToZoom && (
        <ZoomedImagePopup imageUrl={imageToZoom} onClose={closeZoomedImage} />
      )}
    </div>
  );
};

export default PlanogramPopup;
