import React, { ReactNode, useEffect } from "react";
import TestImg from "../assets/undraw_sync_re_492g.svg";
import Button from "./Button";
import { useNotifications } from "../notification";
interface PopupProps {
  isOpen: boolean;
  children: ReactNode;
  title: string;
  setShowPopup: any;
}

const Popup: React.FC<PopupProps> = ({ isOpen, children, title ,setShowPopup }) => {
  const { DisconnectingSseEvent } = useNotifications();

  useEffect(() => {
    if (title === 'Multiple Device Error') {
      DisconnectingSseEvent();
    }
  }, [title])

  if (!isOpen) return null;

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center  justify-center z-50 ">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-[700px] md:h-auto h-full min-h-[300px]">
        <div className="flex flex-col md:flex-row gap-4 md:h-auto h-full">
          <div className="w-full md:w-[30%] ">
            <img className="h-full w-full" src={TestImg} alt="Img" />
          </div>
          <div className="w-full md:w-[70%] flex flex-col justify-between md:h-auto h-full min-h-[240px]">
            <div className="p-4">
              <h2 className="text-purple pb-2 font-bold">{title}</h2>
              {children}
            </div>
            <div className="flex justify-center">
              <Button type="large" label="Ok" onClick={handlePopupClose} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
